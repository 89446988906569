<template>
  <div>
    <router-link :to="{name: 'WelcomeLanding'}">
      <UIImage class="mb-16" width="130" :name-path="`logo.svg`"/>
    </router-link>

    <h3 class="mb-2 text-title-2 gray-100--text">Verification in progress...</h3>
    <p class="mb-7 text-captions-1 gray-100--text">Please wait until the status changes</p>
  </div>
</template>

<script>
import UIImage from "@/components/UI/UIImage";
import {mapGetters} from "vuex";

export default {
  name: 'VerifyEmail',
  components: {
    UIImage,
  },
  computed: {
    ...mapGetters(['getAuth', 'getAuthId'])
  },
  created() {
    this.sendVerifyEmail();
  },
  methods: {
    async sendVerifyEmail() {
      try {
        await this.$api.auth.verifyEmail({email: this.$route.query.email});
        this.$toast.open({
          message: 'Your verification is successful',
          type: 'success',
          position: 'top-right'
        });
        if(this.getAuth) {
          const user = await this.$api.user.get(this.getAuthId)
          await this.$store.dispatch('setUser', user.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        if (this.getAuth) {
          if(this.$route?.query?.redirect) {
            await this.$router.push({path: this.$route.query.redirect});
          } else {
            await this.$router.push({name: "GlobalDashboard"});
          }
        } else {
          await this.$router.push({name: "Login"});
        }
      }
    },
  }
}
</script>
