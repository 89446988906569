<template>
  <div>
    <router-link :to="{name: 'WelcomeLanding'}">
      <UIImage class="mb-12" width="130" :name-path="`logo.svg`"/>
    </router-link>

    <div class="mb-2 text-captions-1 gray-60--text">Hello, and welcome! 👋</div>
    <EnterSocialEmail v-if="enterSocialEmailForm"/>

    <template v-else>
      <h3 class="mb-8 text-title-2 gray-100--text">Let’s create your account.</h3>

      <social-list
        class="mb-9"
        :firebase-app="firebaseApp"
        @onSocialAuth="token => this.auth({token})"
        @toggleEmailForm="enterSocialEmailForm = true"
      />
      <p class="mb-8 text-captions-1 grey--text">Or use your email for registration</p>

      <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
        <v-form @submit.prevent="handleSubmit(submit)" class="relative mb-8">
          <div v-if="isLoading" class="d-flex justify-center align-center fill-width fill-height absolute inset-0 white" style="z-index: 2">
            <v-progress-circular
              :size="100"
              color="accent"
              indeterminate/>
          </div>

          <ValidationProvider class="input-wrap mb-4" mode="eager" tag="div"
                              name="name" vid="first_name" rules="required|min:1|max:64" v-slot="{ errors }">
            <div class="mb-2 text-body font-weight-light gray-100--text">Name</div>
            <v-text-field
              v-model="form.first_name"
              outlined
              hide-details
              :disabled="isLoading"
              :error="!!errors.length"
              placeholder="Name"
            />
            <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
          </ValidationProvider>

          <ValidationProvider class="input-wrap mb-4" mode="eager" tag="div"
                              name="your e-mail" vid="email" rules="required|email" v-slot="{ errors }">
            <div class="mb-2 text-body font-weight-light gray-100--text">E-mail</div>
            <v-text-field
              v-model="form.email"
              type="email"
              autocomplete="off"
              outlined
              hide-details
              :disabled="isLoading"
              :error="!!errors.length"
              placeholder="Email"
            />
            <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] }}</div>
          </ValidationProvider>

          <ValidationProvider class="input-wrap mb-4" mode="eager" tag="div"
                              name="your password" vid="password" rules="required|min:8|max:100|unique_chars:5|password_spec_char" v-slot="{ errors }">
            <div class="mb-2 text-body font-weight-light gray-100--text">Password</div>
            <v-text-field
              v-model="form.password"
              type="password"
              outlined
              hide-details
              @keydown.space.prevent
              :disabled="isLoading"
              :error="!!errors.length"
              placeholder="Password"
            />
            <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
          </ValidationProvider>

          <ValidationProvider class="input-wrap mb-10" mode="eager" tag="div"
                              name="confirm your password" vid="password_repeat" rules="required|min:8|max:100|unique_chars:5|password_spec_char|confirmed:password" v-slot="{ errors }">
            <div class="mb-2 text-body font-weight-light gray-100--text">Password</div>
            <v-text-field
              v-model="form.password_confirmation"
              type="password"
              outlined
              hide-details
              @keydown.space.prevent
              :disabled="isLoading"
              :error="!!errors.length"
              placeholder="Password"
            />
            <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
          </ValidationProvider>

          <UiBtn
            type="submit"
            width="100%"
            height="40"
            color="accent"
            class="mb-3"
            :disabled="isFormFieldEmpty || invalid"
          >
            Sign Up
          </UiBtn>
        </v-form>
      </ValidationObserver>

      <p class="mb-0 text-captions-1 gray-100--text text-center">
        Already have an account? <router-link :to="{name: 'Login'}" class="accent-80--text">Log In</router-link>
      </p>
    </template>
  </div>
</template>

<script>
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import UIImage from "@/components/UI/UIImage";
import UiBtn from "@/components/UI/UiBtn";
import SocialList from "@/views/auth/SocialList";
import EnterSocialEmail from "@/views/auth/EnterSocialEmail";

export default {
  name: 'Registration',
  props: {
    firebaseApp: {
      required: true,
    }
  },
  components: {
    UIImage,
    UiBtn,
    SocialList,
    EnterSocialEmail,
  },
  data() {
    return {
      isLoading: false,
      form: {
        first_name: '',
        email: '',
        password: '',
        password_confirmation: ''
      },
      enterSocialEmailForm: false,
    }
  },
  computed: {
    isFormFieldEmpty() {
      return Object.keys(this.form).some(key => this.form[key] === '');
    }
  },
  created() {
    const socialAuth = JSON.parse(localStorage.getItem('socialAuth'));
    if (socialAuth && !socialAuth.email) {
      this.enterSocialEmailForm = true;
    }
  },
  methods: {
    async submit () {
      this.isLoading = true;

      try {
        const auth = getAuth(this.firebaseApp);
        const userCredential = await createUserWithEmailAndPassword(auth, this.form.email, this.form.password);
        const user = userCredential.user;

        await this.auth({
          ...this.form,
          token: user.accessToken
        });
      } catch (error) {
        this.isLoading = false;

        if (error.code === 'auth/email-already-in-use') {
          this.$refs.form.setErrors({email: "User with this email already exist"});
        }

        console.error(error);
      }
    },
    async auth(data) {
      this.isLoading = true;
      try {
        const res = await this.$api.auth.registration(data);
        await this.$store.dispatch('setAuth', res);

        this.$toast.open({
          message: `Your registration is successful`,
          type: 'success',
          position: 'top-right'
        });

        this.isLoading = false;
        if(this.$route?.query?.redirect) {
          await this.$router.push({path: this.$route.query.redirect});
        } else {
          await this.$router.push({name: "GlobalDashboard"});
        }
      } catch (error) {
        this.isLoading = false;

        this.$nextTick(() => {
          if (error.response.status === this.$config.http.UNPROCESSABLE_ENTITY) {
            this.$refs.form.setErrors(error.response.data.errors);
          }
        })
        console.error(error);
      }
    }
  }
}
</script>

<style scoped lang="scss">
.auth-page {
  background: linear-gradient(90deg, #DCDDDE 14.08%, rgba(255, 255, 255, 0) 100%), url("~@/assets/images/auth/bg-pattern.png") repeat;
}

.auth-wrap {
  width: 47%;
  padding-left: 50px;
}

.auth {
  margin-right: 90px;
  max-width: 444px;
  box-shadow: 0 0 27px rgba(0, 0, 0, 0.09);

  @media #{map-get($display-breakpoints, 'md-and-down')} {
    margin-right: 50px;
  }
}

.auth-image {
  width: 53%;
}

::v-deep .input-wrap .v-input__slot {
  min-height: 45px;

  input {
    font-size: 12px;
    color: var(--v-gray-60-base);
  }
}
</style>
