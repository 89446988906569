<template>
  <div>
    <h3 class="mb-8 text-title-2 gray-100--text">To continue please enter your email</h3>

    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <v-form @submit.prevent="handleSubmit(submit)" class="relative">
        <div v-if="isLoading" class="d-flex justify-center align-center fill-width fill-height absolute inset-0 white" style="z-index: 2">
          <v-progress-circular
            :size="100"
            color="accent"
            indeterminate/>
        </div>

        <ValidationProvider class="input-wrap mb-7" mode="eager" tag="div"
                            name="your e-mail" vid="email" rules="required|email" v-slot="{ errors }">
          <div class="mb-2 text-body font-weight-light gray-100--text">E-mail</div>
          <v-text-field
            v-model="form.email"
            type="email"
            outlined
            hide-details
            :disabled="isLoading"
            :error="!!errors.length"
            placeholder="Email"
          />
          <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
        </ValidationProvider>

        <UiBtn
          type="submit"
          width="100%"
          height="40"
          color="accent"
          :disabled="form.email === ''"
        >
          Submit
        </UiBtn>
      </v-form>
    </ValidationObserver>
  </div>
</template>

<script>
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: 'ForgotPassword',
  components: {
    UiBtn,
  },
  data() {
    return {
      isLoading: false,
      form: {
        email: '',
        uid: JSON.parse(localStorage.getItem('socialAuth')).uid,
      }
    }
  },
  methods: {
    async submit () {
      this.isLoading = true;

      try {
        const res = await this.$api.auth.setUserEmail(this.form);
        await this.$store.dispatch('setAuth', res);

        localStorage.removeItem('socialAuth');
        if(this.$route?.query?.redirect) {
          await this.$router.push({path: this.$route.query.redirect});
        } else {
          await this.$router.push({name: "GlobalDashboard"});
        }
      } catch (error) {
        if (error.response.status === this.$config.http.UNPROCESSABLE_ENTITY && error.response.data.tokenExpired) {
          localStorage.removeItem('socialAuth');
        }
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    }
  }
}
</script>
